<template>
  <div>
    <h2 class="text-center py-3">{{ data.name }}</h2>
    <div class="py-3">
      <span class="badge me-2" style="background-color: #cb5a55">记忆</span>
      <span class="badge me-2" style="background-color: #2f4f63">评判他人</span>
      <span class="badge me-2" style="background-color: #9cac4b">决策能力</span>
      <span class="badge me-2" style="background-color: #508591">评判自己</span>
      <span class="badge me-2" style="background-color: #69a891"
        >媒体/广告/权威</span
      >
      <span class="badge me-2" style="background-color: #e6b53d">世界观</span>
    </div>
    <div class="row row-cols-1 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 g-3">
      <div v-for="item in data.item" :key="item.id" class="col">
        <div class="card d-flex h-100">
          <div class="text-start">
            <h3>{{ item.id }} · {{ item.name }}</h3>
            <p v-html="item.desc" class="text-break"></p>
            <img
              :src="require('@/assets/' + item.img)"
              :alt="item.name"
              width="150"
              :key="item.id"
            />
            <small class="d-block text-black-50 mt-1">
              {{ item.eg }}
            </small>
            <div
              class="d-flex"
              style="position: absolute; right: 12px; top: 17px"
            >
              <div
                class="me-1"
                v-for="arry in item.tag"
                :key="arry.id"
                v-html="arry"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import data from "../data/card.json";

export default {
  name: "Card",
  data() {
    return {
      data,
    };
  },
};
</script>

<style lang="scss">
h3 {
  font-size: 1.35rem;
}
.cover {
  width: 12rem;
  height: 12rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.card {
  background: #fff;
  padding: 1rem;
  border: none;
  transition: all 0.5s ease-in-out;
  &:hover {
    box-shadow: 0 0 37px rgba(0, 0, 0, 0.07), 0 0 2px rgba(0, 0, 0, 0.12);
  }
}
.btn {
  &:hover {
    svg {
      filter: invert(1);
    }
  }
}


@mixin dot-hover {
  color: #fff;
  width: inherit;
  height: inherit;
  padding: 0px 3px;
}

.dot {
  cursor: pointer;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  transition: all 500ms ease;
  overflow: hidden;
  color: transparent;
  // @include dot-hover;
}


.t01 {
  background-color: #cb5a55;
}
.t02 {
  background-color: #2f4f63;
}
.t03 {
  background-color: #9cac4b;
}
.t04 {
  background-color: #508591;
}
.t05 {
  background-color: #69a891;
}
.t06 {
  background-color: #e6b53d;
}
.t01,
.t02,
.t03,
.t04,
.t05,
.t06 {
  &:hover {
    @include dot-hover;
  }
}
</style>
