<template>
  <div id="app">
    <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
      <div class="container-fluid">
        <a class="navbar-brand text-primary" href="/">
          <img
            src="./assets/logo.svg"
            alt=""
            width="30"
            height="24"
            class="d-inline-block align-text-top"
          />
          50 cognitive biases
        </a>
      </div>
    </nav>
    <div class="container-fluid pt-3">
      <div class="row justify-content-center my-3">
            <div class="col-sm-12 col-md-8">
                <!-- <a href="https://aicc.pro?refer=uxlib.net" target="_blank"><img src="https://dc.uxlib.net/assets/img/aicc01.png" alt="加入AI 知识共创: https://aicc.pro" style="max-width: 100%;height: auto;" /></a> -->
            </div>
        </div>
      <Card />
      <Footer></Footer>
    </div>
  </div>
  </div>

  
</template>

<script>
import Card from './views/Card.vue'
import Footer from './views/Footer.vue'

export default {
  name: 'App',
  components: {
    Card,
    Footer
  }
}
</script>

<style lang="scss">
@import url(https://cdn.bootcdn.net/ajax/libs/twitter-bootstrap/5.3.3/css/bootstrap.min.css);
body {
  background: #f5f6f7;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: #06f !important;
}
</style>
