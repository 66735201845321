<template>
    <div class="text-center mt-3 text-black-50 p-3">
        来自: <strong>h984h</strong>, 原文: <a href="https://www.titlemax.com/discovery-center/lifestyle/50-cognitive-biases-to-be-aware-of-so-you-can-be-the-very-best-version-of-you/" target="_blank" rel="noopener noreferrer">50 cognitive biases : Carly Hallman</a> 推荐: 共享心跳
    </div>
</template>

<script>
export default {
    name: "Footer"
    
}
</script>

<style lang="scss" scoped>

</style>